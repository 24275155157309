import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { classNames } from '~/lib';
import { RichText, SwiperNav } from '../Molecules';
import { StepsCard } from './StepsCard';

import 'swiper/css';

export const Steps = ({ content }) => {
  return (
    <div
      className={classNames(
        'flex flex-col justify-center items-center mb-28 pb-20 relative overflow-hidden',
      )}
    >
      <div className="w-full">
        <div className="max-w-6xl px-4 mx-auto">
          <div className="max-w-2xl text-center md:text-left flex flex-col items-start mb-10 md:mb-16">
            {content?.scroll_title && (
              <RichText
                text={content?.scroll_title}
                className="text-center md:text-left"
              />
            )}
            <div className="mt-6 text-lg">{content?.scroll_subtitle}</div>
          </div>
        </div>
        <div className="px-4 flex flex-col space-y-2 sm:hidden">
          {!!content?.scroll_cards &&
            content?.scroll_cards?.map((card) => (
              <StepsCard key={card?._uid} card={card} />
            ))}
        </div>
        <Swiper
          className="!hidden sm:!block p-swiper"
          spaceBetween={56}
          modules={[Navigation]}
          navigation
          slidesPerView={1.5}
        >
          {!!content?.scroll_cards &&
            content?.scroll_cards?.map((card) => (
              <SwiperSlide key={card?._uid} className="max-w-[807px]">
                <StepsCard card={card} />
              </SwiperSlide>
            ))}
          <SwiperNav />
        </Swiper>
      </div>
    </div>
  );
};
