import { Image } from '@storyofams/storyblok-toolkit';

import { Button } from '~/components';
import { getLinkProps } from '~/lib';
import { Check } from '../Icons';
import { RichText } from '../Molecules';

interface HeroProps {
  _editable?: string;
  [index: string]: any;
  className?: string;
}

export const Hero = ({ content }: HeroProps) => {
  return (
    <div className="mb-20 relative mt-6 lg:mt-48 overflow-x-clip">
      <div className="flex flex-col lg:flex-row max-w-6xl mx-auto">
        <div className="lg:max-w-[480px] flex flex-col items-start px-4 xl:px-0 mt-20 sm:mt-32 lg:mt-0">
          {!!content?.title && (
            <RichText text={content?.title} textClassName="xl:!text-7xl" />
          )}
          <div className="mt-4 md:mt-6 text-grey-800 relative z-10 text-lg xl:text-2xl">
            {content?.subtext}
          </div>
          <div className="w-full md:max-w-sm mt-10 md:mt-12">
            <div className="w-full flex flex-col md:flex-row">
              <Button
                variant="primary"
                size="large"
                className="w-full"
                href={getLinkProps(content?.button_1_link)}
              >
                {content?.button_1}
              </Button>

              {!!content?.button_2 && (
                <Button
                  variant="outline"
                  className="w-full mt-4 md:mt-0 md:ml-4"
                  href={getLinkProps(content?.button_2_link)}
                >
                  {content?.button_2}
                </Button>
              )}
            </div>

            {!content?.button_2 && (!!content?.feat_1 || !!content?.feat_2) && (
              <div className="w-full flex items-center justify-between mt-4">
                {!!content?.feat_1 && (
                  <div className="flex items-center text-sm text-gray-800">
                    <div className="w-6 h-6 bg-yellow-50 rounded-full flex items-center justify-center mr-2">
                      <Check className="w-3" />
                    </div>
                    {content?.feat_1}
                  </div>
                )}
                {!!content?.feat_2 && (
                  <div className="flex items-center text-sm text-gray-800">
                    <div className="w-6 h-6 bg-yellow-50 rounded-full flex items-center justify-center mr-2">
                      <Check className="w-3" />
                    </div>
                    {content?.feat_2}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="mt-10 md:mt-12 mx-4 lg:max-h-[600px] lg:mx-0 lg:mt-0 lg:w-[68%] lg:right-[-15%] xl:w-[58%] xl:right-[-10%] relative lg:absolute lg:top-1/2 lg:-translate-y-1/2">
          <div className="max-w-[1000px] lg:max-h-[600px] rounded-2xl overflow-hidden">
            <Image
              alt={content?.image.alt || ''}
              src={content?.image.filename}
              showPlaceholder={false}
              width="100%"
              fit="contain"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
