import { Image } from '@storyofams/storyblok-toolkit';
import { getLinkProps } from '~/lib';
import { Button } from '../Button';
import { ArrowRight } from '../Icons';
import { RichText } from '../Molecules';

export const CustomerSection = ({ content }) => {
  return (
    <div className="max-w-6xl px-4 mx-auto pb-20 flex flex-col md:flex-row md:justify-between">
      <div className="flex flex-col items-start w-full">
        <div className="lg:max-w-[548px]">
          {!!content?.customer_logo && (
            <Image
              alt={content?.customer_logo?.alt || ''}
              src={content?.customer_logo?.filename}
              fit="contain"
              width={171}
            />
          )}
          {content?.customer_title && (
            <RichText text={content?.customer_title} className="mt-16" />
          )}
          <div className="text-lg mt-4 md:mt-5">
            {content?.customer_subtitle}
          </div>
          <Button
            variant="dark"
            href={getLinkProps(content?.customer_link)}
            className="mt-10 !font-medium md:hidden"
          >
            {content?.customer_button}
            <ArrowRight className="w-2.5 ml-2" />
          </Button>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-y-8 gap-x-4 w-full md:max-w-[672px] mt-12 md:mt-10">
          {content?.customer_results?.map((number) => (
            <div key={number?._uid}>
              <div className="text-5xl text-grey-700 font-bold">
                {number?.before_number}
                <span>{number?.number}</span>
                {number?.after_number}
              </div>
              <div className="mt-2 text-lg text-grey-700">
                {number?.subtext}
              </div>
            </div>
          ))}
        </div>
        <Button
          variant="dark"
          href={getLinkProps(content?.customer_link)}
          className="mt-16 !font-medium hidden md:flex"
        >
          {content?.customer_button}
          <ArrowRight className="w-2.5 ml-2" />
        </Button>
      </div>
      <div className="w-full max-w-sm self-center md:self-end md:mr-6 mt-10 md:mt-0">
        {!!content?.customer_image && (
          <Image
            alt={content?.customer_image?.alt || ''}
            src={content?.customer_image?.filename}
            fit="contain"
            width="100%"
          />
        )}
      </div>
    </div>
  );
};
