import { useState } from 'react';
import { useSwiper } from 'swiper/react';
import { classNames } from '~/lib';
import { ArrowLeft, ArrowRight } from '../Icons';

export const SwiperNav = ({
  className,
  buttonClassName,
  arrowClassName = 'w-4',
}: {
  className?: string;
  buttonClassName?: string;
  arrowClassName?: string;
}) => {
  const swiper = useSwiper();
  const [progress, setProgress] = useState<number>(0);

  return (
    <div className={classNames('mt-7 flex space-x-6', className)}>
      <button
        className={classNames(
          'flex items-center justify-center w-12 h-12 rounded-full border',
          buttonClassName,
          progress === 0
            ? 'border-grey-100'
            : 'border-grey-300 hover:bg-grey-100',
        )}
        onClick={() => {
          swiper.slidePrev();
          setProgress(swiper.progress);
        }}
      >
        <ArrowLeft
          className={classNames(
            arrowClassName,
            progress === 0 ? 'text-grey-200' : 'text-grey-700',
          )}
        />
      </button>

      <button
        className={classNames(
          'flex items-center justify-center w-12 h-12 rounded-full border',
          buttonClassName,
          progress === 1
            ? 'border-grey-100'
            : 'border-grey-300 hover:bg-grey-100',
        )}
        onClick={() => {
          swiper.slideNext();
          setProgress(swiper.progress);
        }}
      >
        <ArrowRight
          className={classNames(
            arrowClassName,
            progress === 1
              ? 'text-grey-200'
              : 'text-grey-700 hover:bg-grey-100',
          )}
        />
      </button>
    </div>
  );
};
