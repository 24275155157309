import { Image } from '@storyofams/storyblok-toolkit';
import { classNames } from '~/lib';
import { Heading } from '../Heading';
import { RichText } from '../Molecules';

export const CardsSection = ({
  content,
  className,
}: {
  content?: any;
  className?: string;
}) => {
  return (
    <div className={classNames('max-w-6xl px-4 mx-auto pb-20', className)}>
      {content?.cards_title && (
        <RichText
          text={content?.cards_title}
          className="text-center max-w-2xl mx-auto"
        />
      )}
      <div className="mt-10 md:mt-16 grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-10">
        {content?.cards?.map((card, i) => (
          <div
            key={card?._uid}
            className={classNames(
              'p-6 md:p-12 rounded-3xl odd:bg-yellow-50 even:bg-white even:border even:border-grey-100',
              i % 3 === 0
                ? 'md:!bg-yellow-50 md:!border-none'
                : 'md:!bg-white md:!border md:!border-grey-100',
            )}
          >
            <div className="w-24 h-24 rounded-full overflow-hidden">
              {!!card?.image && (
                <Image
                  alt={card?.image?.alt || ''}
                  src={card?.image?.filename}
                  fit="cover"
                  width="100%"
                  height="100%"
                  className="rounded-full"
                />
              )}
            </div>
            <Heading
              as="h3"
              variant="h3"
              className="!font-medium mt-8 md:mt-10 text-grey-800"
            >
              {card?.text}
            </Heading>
            {card?.subtext && (
              <RichText
                text={card?.subtext}
                className="mt-4 md:mt-6"
                textClassName="text-lg text-grey-800"
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
