import { withStory, WithStoryProps } from '@storyofams/storyblok-toolkit';
import { GetStaticProps } from 'next';
import dynamic from 'next/dynamic';
import SbEditable from 'storyblok-react';

import { Hero, Seo } from '~/components';
import { BannerDynamic } from '~/components/Banners/BannerDynamic';
import { CardsSection, CustomerSection, Steps } from '~/components/Home';

import { addFormFieldsToStory, enhancedStaticProps } from '~/lib';
import { withLayout } from '~/lib/withLayout';

const DataVisualisation = dynamic(
  () => import('../components/Home/DataVisualisation'),
  {
    ssr: false,
  },
);

const LogoSection = dynamic(() => import('~/components/Blocks/LogoSection'), {
  ssr: false,
});

const DoubleAnimationSection = dynamic(
  () => import('../components/Home/DoubleAnimationSection'),
  {
    ssr: false,
  },
);

export interface HomeProps extends WithStoryProps {}

const Home = ({ story }: HomeProps) => {
  const content = story?.content;

  return (
    <>
      <Seo story={story} />

      <SbEditable content={content}>
        <Hero content={content} />

        {content?.logo_section?.length > 0 && (
          <LogoSection
            content={content?.logo_section?.[0]}
            kickerClassName="font-sans font-normal normal-case text-gray-400 leading-relaxed px-2"
          />
        )}

        <DoubleAnimationSection content={content} />

        <DataVisualisation content={content} className="py-20" />

        <Steps content={content} />

        <CustomerSection content={content} />

        {content?.banner_first?.content && (
          <div className="max-w-6xl px-4 mx-auto py-20 mb-20">
            <BannerDynamic content={content?.banner_first?.content} />
          </div>
        )}

        <CardsSection content={content} />

        {content?.banner_last?.content && (
          <div className="max-w-6xl mx-auto px-4 py-20 mb-20">
            <BannerDynamic content={content?.banner_last?.content} />
          </div>
        )}
      </SbEditable>
    </>
  );
};

export default withLayout(withStory(Home));

export const getStaticProps: GetStaticProps = enhancedStaticProps(
  async ({ sdk }) => {
    let story = (await sdk.homeItem({ slug: 'home' })).HomeItem;
    story = await addFormFieldsToStory(story);

    return {
      props: {
        story,
      },
      notFound: !story,
      revalidate: 60,
    };
  },
);
