import { Image } from '@storyofams/storyblok-toolkit';
import { classNames } from '~/lib';
import { Heading } from '../Heading';
import { RichText } from '../Molecules';

export const StepsCard = ({ card }) => {
  return (
    <div key={card?._uid} className="rounded-2xl overflow-hidden">
      <div className="relative">
        <div
          className={classNames(
            'relative z-0 w-full',
            card?.image_mobile && 'hidden md:block',
          )}
        >
          {!!card?.image && (
            <Image
              alt={card?.image?.alt || ''}
              src={card?.image?.filename}
              fit="contain"
              width="100%"
            />
          )}
        </div>
        {!!card?.image_mobile && (
          <div className="md:hidden w-full">
            <Image
              alt={card?.image_mobile?.alt || ''}
              src={card?.image_mobile?.filename}
              fit="contain"
              width="100%"
            />
          </div>
        )}
        <div
          className={classNames(
            'absolute top-0 z-20 w-full px-6 py-7 md:p-14',
            card?.text_color === 'light' && 'text-white',
          )}
        >
          {!!card?.text && (
            <Heading
              as="h3"
              variant="h2"
              className={classNames(
                'md:!font-medium',
                card?.text_color === 'light' && '!text-white',
              )}
            >
              {card?.text}
            </Heading>
          )}
          {!!card?.subtext && (
            <RichText
              text={card?.subtext}
              className="mt-8 md:mt-6"
              textClassName={classNames(
                'sm:text-xl',
                card?.text_color === 'light' && '!text-white',
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};
